// 解析url参数(解析__add_url, __source, __campaign)
export const parseUrlParams = () => {
  try {
    if (/^\?deepLink=/i.test(location.search)) {
      const params = location.search
        .slice(1)
        .split("&")
        .map((kv) => kv.split("="))
        .reduce((c, n) => ({ ...c, [n[0]]: n[1] }), {});
      let deepLink = decodeURIComponent(params.deepLink);
      // NOTICES： 兼容deepLink 被自动解析（所以 urlEncode 两次的情况)
      if (/%2F/.test(deepLink)) {
        deepLink = decodeURIComponent(deepLink);
      }
      params.deepLink = deepLink;
      return {
        ...decodeURIComponent(location.search)
          .split("&")
          .map((kv) => kv.split("="))
          .reduce((c, n) => ({ ...c, [n[0]]: n[1] }), {}),
        ...params,
      };
    } else {
      const decodeSearchString = decodeURIComponent(location.search).slice(1);
      const otherParams = decodeSearchString
        .split("&")
        .map((kv) => kv.split("="))
        .reduce((c, n) => ({ ...c, [n[0]]: n[1] }), {});
      // 处理非telegram过来的链接, 支持add_url, source, campaign这三个key
      let addUrl = decodeSearchString.match(
        /__add_url=(.+)(&__source|&__campaign|&)?/i
      )?.[1];
      let source = decodeSearchString.match(
        /source=(.+)(&__add_url|&__campaign|&)?/i
      )?.[1];
      let campaign = decodeSearchString.match(
        /__campaign=(.+)(&__add_url|&__source|&)?/i
      )?.[1];

      // 判断addUrl是否是最后一个key
      const r1 = addUrl?.split("&__source");
      const r2 = addUrl?.split("&__campaign");
      if (r1?.length > 1 || r2?.length > 1) {
        const matchCount =
          Math.min(1, r1.length - 1) + Math.min(1, r2.length - 1);
        for (let count = 0; count < matchCount; count++) {
          addUrl = addUrl.match(/(.+)(&__source|&__campaign)/i)?.[1];
        }
      }
      // 判断source是否为最后一个key
      source = source?.split("&")[0];
      // campaign不会包含&符号
      campaign = campaign?.split("&")[0];
      if (source) {
        otherParams.__source = source;
      }
      if (campaign) {
        otherParams.__campaign = campaign;
      }
      return {
        ...otherParams,
        __add_url: addUrl,
      };
    }
  } catch (err) {
    console.error("[parserUrlParams]", err);
    return {};
  }
};

export const isSubscribe = () => {
  const params = parseUrlParams();
  const channel = params.__source || params.from;
  return [
    ...new Array(7).fill(0).map((_, i) => `zy00${12 + i}`),
    ...new Array(9).fill(0).map((_, i) => `zy00${23 + i}`),
    "zy0033",
  ].includes(channel);
};

// 通过parseUrlParams解析出来的参数, 获取深度链接
export const getDeepLink = () => {
  const params = parseUrlParams();
  if (params.deepLink) {
    // telegram过来的链接
    let deepLink = params.deepLink;
    if (params.from) {
      deepLink = deepLink + "&from=" + params.from;
    }
    return deepLink;
  } else {
    // 合作方的提供的链接
    let from = params.__source ? `${params.__source}/page` : `other/page`;
    if (params.__campaign) {
      from = from + "/" + params.__campaign;
    }
    let deepLink = `/xpan/main_tab?tab=1&add_url=${encodeURIComponent(
      params.__add_url
    )}&from=${encodeURIComponent(from)}`;
    if (isSubscribe()) {
      deepLink = `/xpan/main_tab?tab=0&from=${encodeURIComponent(from)}`;
    }

    return deepLink;
  }
};

export const tbbavPics = [
  "https://static.mypikpak.com/2e18dfd1d4c925855646e0c94bc390a29beb01f6",
  "https://static.mypikpak.com/d8f51d1355c8c0b4e85bbf82a1ab35d6cd63dc83",
  "https://static.mypikpak.com/ddc8f104ae4ce6162a0f8fcdd740e476c30c320a",
  "https://static.mypikpak.com/101a4efc64a739770c30fbb63a91799c0fc3750c",
  "https://static.mypikpak.com/b66392fd9e5991be580f6dbec2ae225c8251d269",
  "https://static.mypikpak.com/06be7a15c48dcd1a36edbbdbd736f07c94760d0b",
  "https://static.mypikpak.com/705aaf41e82f068238b263d5aee72585335195b6",
  "https://static.mypikpak.com/45fd9a056d5e9a5d39e732b3004e860be3f43afa",
  "https://static.mypikpak.com/54a356627e11ebf641f70f7f8cc956de323f25be",
  "https://static.mypikpak.com/3ebe55a5463863749f2167d1cbda2f3c9e7c8543",
];

export const subscribeInfoMapping = {
  zy0012: {
    name: "发布渠道-内部测试",
    pic: "https://static.mypikpak.com/32a3f14e7ce4a1d53a5fce978671f992c56e90d9",
  },
  zy0013: {
    name: "PikPak磁链资源分享",
    pic: "https://static.mypikpak.com/82901ddbe21c7b97cdfc419156fa8cabe69f3472",
  },
  zy0014: {
    name: "每日JAV精选",
    pic: "https://static.mypikpak.com/ebdc80d595630b1088a8d69318025e98a110a33e",
  },
  zy0015: {
    name: "動漫花園|新番推送",
    pic: "https://static.mypikpak.com/32a3f14e7ce4a1d53a5fce978671f992c56e90d9",
  },
  zy0016: {
    name: "TBBAV",
    pic: tbbavPics[~~(tbbavPics.length * Math.random())],
  },
  zy0017: {
    name: "4k影视屋",
    pic: "https://static.mypikpak.com/a136f3e897b9c3c6dd85dc2bd512a4269bdbab7f",
  },
  zy0018: {
    name: "4K磁力存放",
    pic: "https://static.mypikpak.com/c384ad5915cf31d629da70707928bd43d484858d",
  },
  zy0023: {
    name: "网红成人直播视频精选",
    pic: tbbavPics[~~(tbbavPics.length * Math.random())],
  },
  zy0024: {
    name: "SWAG/麻豆/91/国产/精选AV/成人福利",
    pic: tbbavPics[~~(tbbavPics.length * Math.random())],
  },
  zy0025: {
    name: "秀人美女套图/巨乳/丝袜/高跟/写真/高清/下载",
    pic: tbbavPics[~~(tbbavPics.length * Math.random())],
  },
  zy0026: {
    name: "麻豆原创-精选自拍-新片速递",
    pic: tbbavPics[~~(tbbavPics.length * Math.random())],
  },
  zy0027: {
    name: "国产女神反差露出探花",
    pic: tbbavPics[~~(tbbavPics.length * Math.random())],
  },
  zy0028: {
    name: "夜夜笙箫91学生中文偷拍",
    pic: tbbavPics[~~(tbbavPics.length * Math.random())],
  },
  zy0029: {
    name: "91夯哥超清偷拍学生福利",
    pic: tbbavPics[~~(tbbavPics.length * Math.random())],
  },
  zy0030: {
    name: "OnlyFans付费精选",
    pic: tbbavPics[~~(tbbavPics.length * Math.random())],
  },
  zy0031: {
    name: "收藏AV",
    pic: tbbavPics[~~(tbbavPics.length * Math.random())],
  },
  zy0033: {
    name: "CAO精选-AV",
    pic: tbbavPics[~~(tbbavPics.length * Math.random())],
  },
};

// 获取apk下载链接
export const getChannelApkLink = () => {
  try {
    const params = parseUrlParams();
    const channel = params.__source || params.from;
    const partnerList = [
      "google",
      "yy",
      "dmhy",
      "facebook",
      "spread",
      "cao",
      "webcloud",
      "official",
      "fz",
      "runbkk",
      "sqstore",
      "4kysw",
      "mrfl",
      "jcy",
      "acgnx",
      "24pd",
      "fanhao",
      "hihbt",
      "xcbot",
      "jhs",
      "fz2",
      // 预埋100个zy000x的apk包地址
      ...[...new Array(100)]
        .map((_, i) => i + 1)
        .map((v) => "zy" + `${v}`.padStart(4, "0")),
    ];
    const partnerAPKMapping = {};
    partnerList.forEach((type) => {
      partnerAPKMapping[
        type
      ] = `https://download.mypikpak.com/app/${type}_PikPak.apk`;
    });
    for (let k in partnerAPKMapping) {
      const reg = RegExp(`^${k}$`, "i");
      if (reg.test(channel)) {
        return partnerAPKMapping[k];
      }
    }
  } catch (err) {
    console.error("[match resource channel:]", err);
  }
  return "https://download.mypikpak.com/app/default_PikPak.apk";
};
